import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { TransactionPeriodList } from './pages'

export const TransactionPeriodPages = () => {
  return (
    <CrudPage name="transactionPeriod" actions={['list']}>
      <ListPage title="Calendario de movimientos" hideAddButton>
        <TransactionPeriodList></TransactionPeriodList>
      </ListPage>
    </CrudPage>
  )
}
