import * as React from 'react'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useTransactionPeriodList } from '../data/Queries'
import { TransactionPeriod } from '../models/TransactionPeriod'
import { Column } from 'react-table'
import { Card, Toolbar, Typography } from '@material-ui/core'
import {
  TransactionPeriodToggle,
  TransactionPeriodYearFilter,
} from '../components'
import { useToggleTransactionPeriodMutation } from '../data'
import { useSnackbar } from 'notistack'
import { TableToolbarProps } from 'shared/common/DataTable/components/TableToolbar'
import { WithPermissions } from 'auth/components/WithPermissions'

export const TransactionPeriodList = () => {
  const [loadingId, setLoadingId] = React.useState('')
  const [year, setYear] = React.useState(new Date().getFullYear())
  const { enqueueSnackbar } = useSnackbar()

  const { data, refetch, loading } = useTransactionPeriodList({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      year,
    },
  })
  const [togglePeriod] = useToggleTransactionPeriodMutation()

  const onToggle = React.useCallback(
    async (id: string, currentValue: boolean) => {
      try {
        setLoadingId(id)
        await togglePeriod({ variables: { id } })
        refetch()
      } catch (error) {
        console.warn(error)
        const action = currentValue ? 'cerrar' : 'abrir'
        enqueueSnackbar(
          `Ha ocurrido un error al intentar ${action} el periodo. Intentelo más tarde`,
          { variant: 'error' },
        )
      }
      setLoadingId('')
    },
    [togglePeriod, enqueueSnackbar, refetch],
  )

  const columns = React.useMemo<Column<TransactionPeriod>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Inicio',
        accessor: 'start',
      },
      {
        Header: 'Fin',
        accessor: 'end',
      },
      {
        Header: 'Estado',
        accessor: 'open',
        Cell: ({ value, row }) => {
          const { original } = row
          return (
            <WithPermissions permissions={['transactionPeriod.update']}>
              <TransactionPeriodToggle
                period={original}
                loadingId={loadingId}
                onToggle={() => onToggle(original.id, value)}
              ></TransactionPeriodToggle>
            </WithPermissions>
          )
        },
      },
    ]
  }, [onToggle, loadingId])

  const rows = React.useMemo(() => {
    return data?.listTransactionPeriod ?? []
  }, [data])

  const tableInstance = useDataTable({
    pagination: false,
    selectable: false,
    columns,
    data: rows,
  })

  const toolbar = React.useCallback(
    ({ count }: TableToolbarProps) => {
      return (
        <Toolbar className="flex">
          <Typography variant="subtitle1" className="flex-1">
            Total de {count} registros
          </Typography>
          <TransactionPeriodYearFilter
            className="w-256"
            label="Año"
            placeholder="Seleccionar año"
            value={year}
            onChange={setYear}
            disabled={loading}
          ></TransactionPeriodYearFilter>
        </Toolbar>
      )
    },
    [year, loading],
  )

  return (
    <Card>
      <DataTable ToolbarComponent={toolbar} {...tableInstance}></DataTable>
    </Card>
  )
}
