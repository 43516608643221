import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { TransactionPeriod } from '../models/TransactionPeriod'

export type ListTransactionPeriodResponse = {
  listTransactionPeriod: TransactionPeriod[]
}

export type ListTransactionPeriodVariables = {
  year: number
}

const LIST_TRANSACTION_PERIOD_QUERY = gql`
  query ListTransactionPeriod($year: Int!) {
    listTransactionPeriod(year: $year) {
      id
      name
      start
      end
      open
    }
  }
`

export const useTransactionPeriodList = (
  options?: QueryHookOptions<
    ListTransactionPeriodResponse,
    ListTransactionPeriodVariables
  >,
) => {
  const result = useQuery<
    ListTransactionPeriodResponse,
    ListTransactionPeriodVariables
  >(LIST_TRANSACTION_PERIOD_QUERY, options)
  return result
}

export type ListTransactionPeriodYearsResponse = {
  listTransactionPeriodYears: {
    years: number[]
  }
}

const LIST_TRANSACTION_PERIOD_YEARS_QUERY = gql`
  query {
    listTransactionPeriodYears {
      years
    }
  }
`

export const useTransactionPeriodYearsList = (
  options?: QueryHookOptions<ListTransactionPeriodYearsResponse>,
) => {
  const result = useQuery<ListTransactionPeriodYearsResponse>(
    LIST_TRANSACTION_PERIOD_YEARS_QUERY,
    options,
  )
  return result
}
