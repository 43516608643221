import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import {
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CheckboxForm, Form, SelectForm, TextFieldForm } from 'shared/forms'
import {
  RegisterOutcomePayload,
  RegisterPaymentPayload,
  useRegisterOutcomeMutation,
} from '../data/Mutations'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import { Company, CompanyType } from 'modules/company/models/Company'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import { Organization } from 'modules/organization/models/Organization'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { DatePickerForm } from 'shared/forms/DatePickerForm'
import {
  PaymentMethod,
  outcomePaymentMethodsOptions,
  OutcomeType,
  outcomeTypes,
} from '../models/Outcome'
import { Credit, CreditKind } from 'modules/credit/models/Credit'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { CreditCardAutocomplete } from 'modules/credit-card/components/CreditCardAutocomplete'
import { CreditAutocomplete } from 'modules/credit/components/CreditAutocomplete'
import { useQueryParams } from 'shared/hooks'
import { useInitializeAutocomplete } from 'shared/hooks/UseInitializeAutocomplete'

interface CreateParams {
  company?: string
  category?: string
  paymentMethod?: string
  organization?: string
  bankAccount?: string
  creditCard?: string
  amount: string
}

interface CreateForm {
  outcomeType: OutcomeType

  date: dayjs.Dayjs
  amount: number
  notes?: string
  company: Company
  category: CategoryMove
  organization?: Organization
  paymentMethod: PaymentMethod
  bankAccount?: BankAccount
  creditCard?: CreditCard
  credit?: Credit
  hasTaxReceipt?: boolean
  isDirectDebit?: boolean

  paymentCreditCard: CreditCard
  paymentCredit: Credit
  capital?: number
  interest?: number
  otherAmount?: number
}

const allowedPaymentMethods = outcomePaymentMethodsOptions.map(pm => pm.value)

const schema = yup.object().shape({
  amount: yup
    .number()
    .nullable()
    .moreThan(0, 'Debe ingresar un monto mayor')
    .required('El monto del egreso es requerido'),
  date: yup
    .object()
    .nullable()
    .test('test-date', 'La fecha debe ser mayor al 2021', function (value) {
      const minDate = dayjs('01-01-2021', 'DD-MM-YYYY')
      const ddate = dayjs(new Date(value.toString()))
      return !ddate.isBefore(minDate)
    })
    .required('La fecha del egreso es requerida'),
  notes: yup.string().isOptional(),
  company: yup
    .object()
    .nullable()
    .required('Debe asignar un cliente al egreso'),
  category: yup
    .object()
    .nullable()
    .required('Debe asignar una categoría al egreso'),
  organization: yup
    .object()
    .nullable()
    .required('Debe asignar una organización al egreso'),
  paymentMethod: yup
    .string()
    .oneOf(allowedPaymentMethods, 'El método de pago no es correcto')
    .required('Debe asignar un método de pago al egreso'),
  bankAccount: yup.object().when('paymentMethod', {
    is: paymentMethod =>
      paymentMethod !== PaymentMethod.cash &&
      paymentMethod !== PaymentMethod.credit_card &&
      paymentMethod !== PaymentMethod.credit,
    then: yup
      .object()
      .nullable()
      .required('Debe asignar una cuenta bancaria al egreso'),
  }),
  creditCard: yup.object().when('paymentMethod', {
    is: paymentMethod => paymentMethod === PaymentMethod.credit_card,
    then: yup
      .object()
      .nullable()
      .required('Debe asignar una tarjeta de crédito al egreso'),
  }),
  paymentCreditCard: yup.object().when('outcomeType', {
    is: outcomeType => outcomeType === OutcomeType.creditCard,
    then: yup
      .object()
      .nullable()
      .required('Debe elegir la tarjeta de crédito que desea pagar'),
  }),
  credit: yup.object().when('paymentMethod', {
    is: paymentMethod => paymentMethod === PaymentMethod.credit,
    then: yup.object().nullable().required('Debe asignar un crédito al egreso'),
  }),

  paymentCredit: yup.object().when('outcomeType', {
    is: outcomeType => outcomeType === OutcomeType.credit,
    then: yup
      .object()
      .nullable()
      .required('Debe elegir el crédito que desea pagar'),
  }),
  capital: yup.number().when('outcomeType', {
    is: outcomeType => outcomeType === OutcomeType.credit,
    then: yup
      .number()
      .nullable()
      .moreThan(0, 'Debe ingresar un monto mayor a 0')
      .required('El monto a capital del pago que esta realizando es requerido'),
  }),
  interest: yup.number().nullable(),
  otherAmount: yup.number().nullable(),
})

const dialogId = 'outcomes/create-dialog'
const formId = 'outcomes/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [isEntered, setIsEntered] = React.useState(false)

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      outcomeType: OutcomeType.expense,

      amount: null,
      date: dayjs(),
      notes: '',
      company: null,
      category: null,
      organization: null,
      paymentMethod: PaymentMethod.cash,
      bankAccount: null,
      creditCard: null,
      credit: null,
      hasTaxReceipt: false,
      isDirectDebit: false,

      paymentCreditCard: null,
      paymentCredit: null,
      capital: null,
      interest: null,
      otherAmount: null,
    },
  })

  const { formState, setValue } = formInstance

  const params = useQueryParams<CreateParams>()

  const amountParam = params.amount
  const companyParam = params.company
  const categoryParam = params.category
  const organizationParam = params.organization
  const paymentMethodParam = params.paymentMethod

  const [onCompanyOptionsLoaded] = useInitializeAutocomplete<Company>({
    find: React.useCallback(op => op.id === companyParam, [companyParam]),
    setValue: React.useCallback(val => setValue('company', val), [setValue]),
    validate: React.useCallback(() => isEntered, [isEntered]),
  })

  const [onCategoryOptionsLoaded] = useInitializeAutocomplete<CategoryMove>({
    find: React.useCallback(op => op.id === categoryParam, [categoryParam]),
    setValue: React.useCallback(val => setValue('category', val), [setValue]),
    validate: React.useCallback(() => isEntered, [isEntered]),
  })

  const [onOrganizationOptionsLoaded] = useInitializeAutocomplete<Organization>(
    {
      find: React.useCallback(op => op.id === organizationParam, [
        organizationParam,
      ]),
      setValue: React.useCallback(val => setValue('organization', val), [
        setValue,
      ]),
      validate: React.useCallback(() => isEntered, [isEntered]),
    },
  )

  const [addOutcome, { loading }] = useRegisterOutcomeMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const { isDirty } = formState

  React.useEffect(() => {
    if (!isEntered) {
      return
    }

    setValue('amount', Number(amountParam))
  }, [isEntered, setValue, amountParam])

  React.useEffect(() => {
    if (!isEntered || !paymentMethodParam) {
      return
    }

    setValue('paymentMethod', paymentMethodParam)
  }, [isEntered, setValue, paymentMethodParam])

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const {
        outcomeType,
        date,
        organization,
        category,
        bankAccount,
        company,
        creditCard,
        credit,
        paymentCredit,
        paymentCreditCard,
        capital,
        interest,
        otherAmount,
        ...outcomeData
      } = values

      const isPayment = outcomeType !== OutcomeType.expense
      const dateOnly = date.format('YYYY-MM-DD')

      const outcome: RegisterOutcomePayload = {
        date: date.toDate(),
        dateOnly,
        organizationId: organization.id,
        categoryId: category.id,
        companyId: company.id,
        bankAccountId: bankAccount?.id,
        creditCardId: creditCard?.id,
        creditId: credit?.id,
        ...outcomeData,
      }

      let payment: RegisterPaymentPayload

      if (isPayment) {
        payment = {
          outcomeType,
          debtId: paymentCreditCard?.id ?? paymentCredit?.id,
          capital,
          interest,
          otherAmount,
          ...outcome,
        }
      }

      await addOutcome({
        variables: {
          data: {
            isPayment,
            outcome: !isPayment ? outcome : undefined,
            payment,
          },
        },
      })

      enqueueSnackbar('Egreso registrado correctamente', { variant: 'success' })
      onClose()
    } catch (e) {
      console.warn(e)
      let message =
        'Ha ocurrido un error al registrar el egreso. Intentelo más tarde'
      if (e?.message === 'The date is not in an open period') {
        message = 'La fecha seleccionada se encuentra en un periodo cerrado'
      }
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/outcomes')
  }

  const onEntered = () => {
    setIsEntered(true)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEnforceFocus
      open={isOpen}
      onEntered={onEntered}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Registrar egreso</DialogTitle>
      <DialogContent>
        <Form
          formProps={{ id: formId }}
          onSubmit={onSubmit}
          onError={console.log}
          {...formInstance}
        >
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <SelectForm
                name="outcomeType"
                label="Tipo de egreso"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {outcomeTypes.map(pm => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
            <Grid xs={6} item>
              <CreditCardToPayForm></CreditCardToPayForm>
              <CreditToPayForm></CreditToPayForm>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="company"
                onOptionsLoaded={onCompanyOptionsLoaded}
                type={[
                  CompanyType.supplier,
                  CompanyType.employee,
                  CompanyType.debtor,
                ]}
                inputProps={{
                  label: 'Proveedor/Empleado/Acreedor',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar Proveedor/Empleado/Acreedor"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <OrganizationAutocomplete
                onOptionsLoaded={onOrganizationOptionsLoaded}
                name="organization"
                inputProps={{
                  label: 'Organización',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar organización"
                className="mb-16"
                fullWidth
              ></OrganizationAutocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CategoryMoveAutocomplete
                onOptionsLoaded={onCategoryOptionsLoaded}
                type={MoveType.outcome}
                name="category"
                inputProps={{
                  label: 'Categoría',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar categoría"
                className="mb-16"
                fullWidth
              ></CategoryMoveAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="paymentMethod"
                label="Método de pago"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {outcomePaymentMethodsOptions.map(pm => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
          <BankAccountForm
            bankAccountParam={params.bankAccount}
          ></BankAccountForm>
          <CreditCardForm></CreditCardForm>
          <CreditForm></CreditForm>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <DatePickerForm
                name="date"
                label="Fecha del egreso"
                inputProps={{
                  variant: 'outlined',
                  required: true,
                  fullWidth: true,
                }}
                minDate={dayjs('01-01-2021', 'DD-MM-YYYY')}
                maxDate={dayjs()}
              ></DatePickerForm>
            </Grid>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="amount"
                label="Monto"
                placeholder="Ingresar monto del egreso"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <CreditPaymentForm></CreditPaymentForm>
          <PaymentForm></PaymentForm>
          <TextFieldForm
            name="notes"
            label="Notas"
            placeholder="Ingresar notas"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            fullWidth
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CheckboxForm
                color="primary"
                name="hasTaxReceipt"
                label="¿Tiene comprobante fiscal?"
              ></CheckboxForm>
            </Grid>
            <Grid xs={6} item>
              <CheckboxForm
                color="primary"
                name="isDirectDebit"
                label="¿Es cargo domiciliado?"
              ></CheckboxForm>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function BankAccountForm({ bankAccountParam }: { bankAccountParam: string }) {
  const { getValues, setValue, control } = useFormContext()

  const paymentMethod = useWatch<PaymentMethod>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  const [onOptionsLoaded] = useInitializeAutocomplete<BankAccount>({
    find: React.useCallback(op => op.id === bankAccountParam, [
      bankAccountParam,
    ]),
    setValue: React.useCallback(val => setValue('bankAccount', val), [
      setValue,
    ]),
    validate: React.useCallback(() => true, []),
  })

  return paymentMethod !== PaymentMethod.cash &&
    paymentMethod !== PaymentMethod.credit_card &&
    paymentMethod !== PaymentMethod.credit ? (
    <BankAccountAutocomplete
      onOptionsLoaded={onOptionsLoaded}
      name="bankAccount"
      inputProps={{
        label: 'Cuenta de banco',
        variant: 'outlined',
        required: true,
      }}
      placeholder="Buscar cuenta de banco"
      className="mb-16"
      fullWidth
    ></BankAccountAutocomplete>
  ) : null
}

function CreditCardToPayForm() {
  const { getValues, control } = useFormContext()

  const outcomeType = useWatch<OutcomeType>({
    control,
    name: 'outcomeType',
    defaultValue: getValues('outcomeType'),
  })

  return outcomeType === OutcomeType.creditCard ? (
    <CreditCardAutocomplete
      name="paymentCreditCard"
      inputProps={{
        label: 'Tarjeta de crédito a pagar',
        variant: 'outlined',
        required: true,
      }}
      placeholder="Buscar tarjeta"
      className="mb-16"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}

function CreditToPayForm() {
  const { getValues, control } = useFormContext()

  const outcomeType = useWatch<OutcomeType>({
    control,
    name: 'outcomeType',
    defaultValue: getValues('outcomeType'),
  })

  return outcomeType === OutcomeType.credit ? (
    <CreditAutocomplete
      name="paymentCredit"
      inputProps={{
        label: 'Crédito a pagar',
        variant: 'outlined',
        required: true,
      }}
      placeholder="Buscar el crédito"
      className="mb-16"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}

function CreditForm() {
  const { getValues, control } = useFormContext()

  const paymentMethod = useWatch<PaymentMethod>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  return paymentMethod === PaymentMethod.credit ? (
    <CreditAutocomplete
      name="credit"
      kind={[CreditKind.provider]}
      inputProps={{
        label: 'Crédito de proveedor',
        variant: 'outlined',
        required: true,
      }}
      placeholder="Buscar el crédito"
      className="mb-16"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}

function CreditPaymentForm() {
  const { getValues, control } = useFormContext()

  const outcomeType = useWatch<OutcomeType>({
    control,
    name: 'outcomeType',
    defaultValue: getValues('outcomeType'),
  })

  return outcomeType === OutcomeType.credit ? (
    <TextFieldForm
      TextComponent={CurrencyTextField}
      name="capital"
      label="A Capital"
      placeholder="Ingresar monto para capital"
      variant="outlined"
      className="mb-16"
      required
      fullWidth
    />
  ) : null
}

function PaymentForm() {
  const { getValues, control } = useFormContext()

  const outcomeType = useWatch<OutcomeType>({
    control,
    name: 'outcomeType',
    defaultValue: getValues('outcomeType'),
  })

  return outcomeType !== OutcomeType.expense ? (
    <Grid container spacing={2}>
      <Grid xs={6} item>
        <TextFieldForm
          TextComponent={CurrencyTextField}
          name="interest"
          label="Interés"
          placeholder="Ingresar monto de interés"
          variant="outlined"
          className="mb-16"
          fullWidth
        />
      </Grid>
      <Grid xs={6} item>
        <TextFieldForm
          TextComponent={CurrencyTextField}
          name="otherAmount"
          label="Otros cargos"
          placeholder="Ingresar otros cargos"
          variant="outlined"
          className="mb-16"
          fullWidth
        />
      </Grid>
    </Grid>
  ) : null
}

function CreditCardForm() {
  const { getValues, control } = useFormContext()

  const paymentMethod = useWatch<PaymentMethod>({
    control,
    name: 'paymentMethod',
    defaultValue: getValues('paymentMethod'),
  })

  return paymentMethod === PaymentMethod.credit_card ? (
    <CreditCardAutocomplete
      name="creditCard"
      inputProps={{
        label: 'Tarjeta de crédito',
        variant: 'outlined',
        required: true,
      }}
      placeholder="Buscar tarjeta"
      className="mb-16"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}
