import { gql, useMutation } from '@apollo/client'
import { TransactionPeriod } from '../models'

export interface ToggleTransactionPeriodResponse {
  toggleTransactionPeriod: TransactionPeriod
}

export interface ToggleTransactionPeriodVariables {
  id: string
}

const TOGGLE_TRANSACTION_PERIOD_MUTATION = gql`
  mutation ToggleTransactionPeriod($id: String!) {
    toggleTransactionPeriod(id: $id) {
      id
      start
      end
      name
      open
    }
  }
`

export const useToggleTransactionPeriodMutation = () => {
  const result = useMutation<
    ToggleTransactionPeriodResponse,
    ToggleTransactionPeriodVariables
  >(TOGGLE_TRANSACTION_PERIOD_MUTATION)
  return result
}
