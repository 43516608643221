import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PrivateContent } from './components/PrivateContent'
import { PrivateLayout } from './components/PrivateLayout'
import { UserPages } from 'modules/user'
import { CompanyPages } from 'modules/company'
import { CreditCardPages } from 'modules/credit-card'
import { BankAccountPages } from 'modules/bank-account'
import { CategoryMovePages } from 'modules/category-move'
import { BankPages } from 'modules/bank'
import { OrganizationsPages } from 'modules/organization'
import { IncomePages } from 'modules/income'
import { OutcomePages } from 'modules/outcome'
import { companyTypes } from 'modules/company/models/Company'
import { moveTypes } from 'modules/category-move/models/CategoryMove'
import { ScheduleOutcomePages } from 'modules/schedule-outcome'
import { TypeCreditPages } from 'modules/type-credit'
import { CreditPages } from 'modules/credit'
import { Dashboard } from 'modules/dashboard'
import { ReportsPages } from 'modules/reports'
import { TransferPages } from 'modules/transfer'
import { UserProfilePages } from 'modules/user-profiles'
import { CustomerCreditPages } from 'modules/customer-credit'
import { TransactionPeriodPages } from 'modules/transaction-period'

const validCompanyRoutes = companyTypes
  .map(ct => `/companies/${ct.value}`)
  .concat(['/companies'])

const validCategoryMoveRoutes = moveTypes.map(
  mt => `/categorymoves/${mt.value}`,
)

export const Private: React.FC = () => {
  return (
    <PrivateLayout>
      <PrivateContent>
        <Route path="/dashboard">
          <Dashboard></Dashboard>
        </Route>
        <Route path="/users">
          <UserPages></UserPages>
        </Route>
        <Route path="/user-profiles">
          <UserProfilePages></UserProfilePages>
        </Route>
        <Route path="/organizations">
          <OrganizationsPages></OrganizationsPages>
        </Route>
        <Route path={validCompanyRoutes}>
          <CompanyPages></CompanyPages>
        </Route>
        <Route path="/banks">
          <BankPages></BankPages>
        </Route>
        <Route path="/bankaccounts">
          <BankAccountPages></BankAccountPages>
        </Route>
        <Route path="/creditcards">
          <CreditCardPages></CreditCardPages>
        </Route>
        <Route path="/incomes">
          <IncomePages></IncomePages>
        </Route>
        <Route path="/outcomes">
          <OutcomePages></OutcomePages>
        </Route>
        <Route path="/transfers">
          <TransferPages></TransferPages>
        </Route>
        <Route path="/schedule-outcomes">
          <ScheduleOutcomePages></ScheduleOutcomePages>
        </Route>
        <Route path={validCategoryMoveRoutes}>
          <CategoryMovePages></CategoryMovePages>
        </Route>
        <Route path="/type-credits">
          <TypeCreditPages></TypeCreditPages>
        </Route>
        <Route path="/reports">
          <ReportsPages></ReportsPages>
        </Route>
        <Route path="/credits">
          <CreditPages></CreditPages>
        </Route>
        <Route path="/customer-credits">
          <CustomerCreditPages></CustomerCreditPages>
        </Route>
        <Route path="/transaction-periods">
          <TransactionPeriodPages></TransactionPeriodPages>
        </Route>
        <Redirect to="/dashboard"></Redirect>
      </PrivateContent>
    </PrivateLayout>
  )
}
