import * as React from 'react'
import clsx from 'clsx'
import {
  LabelDisplayedRowsArgs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { DataTableInstance } from './UseDataTable'
import { Row } from 'react-table'
import { TableToolbar, TableToolbarProps } from './components/TableToolbar'
import { Scrollbars } from 'shared/Scrollbars'
import { usePrevious } from 'shared/hooks'

export interface DataTableProps<Data extends object = {}>
  extends DataTableInstance<Data> {
  onRowClick?: (
    ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<Data>,
  ) => void
  noResultsMessage?: string
  noResultsRender?: () => React.ReactNode
  noToolbar?: boolean
  toolBarContent?: (selectedRows?: Row<Data>[]) => React.ReactNode
  ToolbarComponent?: React.FC<TableToolbarProps>
  footer?: (rows: Row<Data>[]) => React.ReactNode
}

const defaultPerPageOptions = [10, 20, 30]

function getOptionValue(
  opt:
    | number
    | {
        label: string
        value: number
      },
) {
  return typeof opt === 'number' ? opt : opt.value
}

export const DataTable = <Data extends object = {}>({
  onRowClick,
  noResultsMessage = 'No hay resultados',
  noResultsRender,
  toolBarContent,
  noToolbar,
  ToolbarComponent = TableToolbar,
  footer,
  ...props
}: React.PropsWithChildren<DataTableProps<Data>>) => {
  const {
    page,
    headerGroups,
    allColumns,
    prepareRow,
    getTableProps,
  } = props.tableInstance

  const {
    pagination,
    selectable,
    page: pageIndex,
    pageSize,
    selectedCount,
    selectedRows,
    count,
    globalFilter,
    setGlobalFilter,
    gotoPage,
    setPageSize,
  } = props

  const allOption = React.useMemo(() => {
    const value = Math.max(...defaultPerPageOptions.map(getOptionValue), count)
    return {
      label: 'Todos',
      value: value + 1,
    }
  }, [count])

  const rowsPerPageOptions = React.useMemo(() => {
    return [...defaultPerPageOptions, allOption]
  }, [allOption])

  const allValue = allOption.value
  const prevAllValue = usePrevious(allOption.value)

  React.useEffect(() => {
    if (!pagination) {
      setPageSize(allValue)
    }

    if (allValue !== prevAllValue && prevAllValue === pageSize) {
      setPageSize(allValue)
    }
  }, [allValue, prevAllValue, pageSize, setPageSize, pagination])

  const rowsPerPage = React.useMemo(() => {
    const found = rowsPerPageOptions.find(
      opt => getOptionValue(opt) === pageSize,
    )

    if (!found) {
      return getOptionValue(rowsPerPageOptions[rowsPerPageOptions.length - 1])
    }

    return pageSize
  }, [pageSize, rowsPerPageOptions])

  const renderNoResults = () => {
    if (noResultsRender) {
      return noResultsRender()
    }

    return (
      <Typography variant="subtitle1" align="center">
        {noResultsMessage}
      </Typography>
    )
  }

  const renderPage = () => {
    if (!page.length) {
      return (
        <TableRow>
          <TableCell colSpan={allColumns.length}>{renderNoResults()}</TableCell>
        </TableRow>
      )
    }

    return page.map(row => {
      prepareRow(row)
      return (
        <TableRow
          hover
          selected={row.isSelected}
          {...row.getRowProps({
            className: clsx('truncate', {
              'cursor-pointer': !!onRowClick,
              onClick: ev => onRowClick?.(ev, row),
            }),
          })}
        >
          {row.cells.map((cell, index) => {
            return (
              <TableCell
                {...cell.getCellProps()}
                className={clsx('p-4 md:p-12', {
                  'md:pl-20': !selectable && index === 0,
                })}
              >
                {cell.render('Cell')}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setPageSize(Number(event.target.value))
  }

  const labelDisplayedRows = (info: LabelDisplayedRowsArgs) => {
    return `Mostrando registros del ${info.from} al ${info.to} de ${info.count}`
  }

  return (
    <>
      {!noToolbar ? (
        <ToolbarComponent
          count={count}
          selectedCount={selectedCount}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        >
          {toolBarContent?.(selectedRows)}
        </ToolbarComponent>
      ) : null}
      <Scrollbars className="flex flex-1 w-full">
        <Table {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map(headerGroup => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    return (
                      <TableCell
                        className={clsx(
                          'whitespace-nowrap p-4 md:p-12',
                          { 'md:pl-20': !selectable && index === 0 },
                          { 'w-32': column.id === 'expander' },
                        )}
                        {...(!column.canSort
                          ? column.getHeaderProps()
                          : column.getHeaderProps(
                              column.getSortByToggleProps({ title: '' }),
                            ))}
                      >
                        {column.render('Header')}
                        {column.canSort ? (
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                          />
                        ) : null}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableHead>
          <TableBody>{renderPage()}</TableBody>
          {footer?.(page)}
        </Table>
      </Scrollbars>
      {pagination ? (
        <TablePagination
          component="div"
          classes={{ root: 'flex-shrink-0' }}
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage="Registros por página"
        ></TablePagination>
      ) : null}
    </>
  )
}
