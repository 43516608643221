import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Income, PaymentMethod, paymentMethods } from '../models/Income'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteIncomeMutation } from '../data/Mutations'
import { formatCurrency } from 'shared/utils'
import dayjs from 'dayjs'

export interface DeleteProps {
  income: Income
}

const dialogId = 'IncomeDeleteDialog'

function getPaymentMethodName(term: PaymentMethod) {
  return paymentMethods.find(pm => pm.value === term)?.text
}

export const Delete = ({ income }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteIncome] = useDeleteIncomeMutation()

  const deleteCards = async () => {
    try {
      await deleteIncome({ variables: { id: income.id } })
      enqueueSnackbar(`Ingreso eliminado correctamente`, { variant: 'success' })
      close()
    } catch (e) {
      console.warn(e)
      let message =
        'Ha ocurrido un error al eliminar el ingreso. Intentelo más tarde'
      if (e?.message === 'The date is not in an open period') {
        message = 'El ingreso se encuentra en un periodo cerrado'
      }
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <Tooltip title="Eliminar ingreso">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar ingreso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar el ingreso con los siguientes datos?
          </DialogContentText>
          <List dense>
            <ListItem>
              <ListItemText
                primary={`- Fecha: ${dayjs(income.date).format('L')}`}
              />
            </ListItem>
            {income.organization ? (
              <ListItem>
                <ListItemText
                  primary={`- Organización: ${income.organization.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {income.company ? (
              <ListItem>
                <ListItemText primary={`- Cliente: ${income.company.name}`} />
              </ListItem>
            ) : null}
            {income.category ? (
              <ListItem>
                <ListItemText
                  primary={`- Categoría: ${income.category.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {income.bankAccount ? (
              <ListItem>
                <ListItemText
                  primary={`- Cuenta de banco: ${income.bankAccount.name}`}
                />
              </ListItem>
            ) : null}
            <ListItem>
              <ListItemText
                primary={`- Monto: ${formatCurrency(income.amount)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`- Método de pago: ${getPaymentMethodName(
                  income.paymentMethod,
                )}`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCards} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
