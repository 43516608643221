import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core'
import { useTransactionPeriodYearsList } from '../data'

export interface TransactionPeriodYearFilterProps
  extends Omit<SelectProps, 'value' | 'onChange'> {
  value: number
  onChange: (value: number) => void
}

export const TransactionPeriodYearFilter: React.FC<TransactionPeriodYearFilterProps> = ({
  value,
  onChange,
  disabled,
  label,
  ...props
}) => {
  const { data, loading } = useTransactionPeriodYearsList({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const options = data?.listTransactionPeriodYears.years ?? [value]

  return (
    <FormControl>
      <InputLabel id="transaction-period-years-filter-label">
        {label}
      </InputLabel>
      <Select
        labelId="transaction-period-years-filter-label"
        id="transaction-period-years-filter"
        value={value}
        onChange={ev => onChange(ev.currentTarget.value as number)}
        disabled={loading || disabled}
        {...props}
      >
        {options.map(item => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
