import { makeStyles, Switch } from '@material-ui/core'
import { TransactionPeriod } from '../models'

export interface TransactionPeriodToggleProps {
  period: TransactionPeriod
  onToggle: (period: TransactionPeriod) => void
  loadingId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))

export const TransactionPeriodToggle = ({
  period,
  onToggle,
  loadingId,
}: TransactionPeriodToggleProps) => {
  const classes = useStyles()

  return (
    <div className="flex items-center w-120">
      <Switch
        className="self-end"
        checked={period.open}
        onChange={onToggle.bind(null, period)}
        disabled={!!loadingId}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
    </div>
  )
}
